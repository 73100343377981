import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextField from '../Form/TextField';
import CustomSelect from '../Form/CustomSelect';
import Button from '../Form/Button';
import {
  useCreateEmployee,
  useUpdateEmployee,
} from '../../hooks/queries-and-mutations/business';
import { useGetDepartment, useGetRole } from '../../hooks/queries-and-mutations/settings';
import { useAppSelector } from '../../store/hooks';
import { selectBusiness } from '../../selectors/business-selector';
import { EmployeeDoc } from '../../types/business.type';
import { InfoModal } from './InfoModal';
import { toast } from 'react-toastify';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  selectedEmployee: EmployeeDoc | null;
  setEmployeeDetails?: (prev: any) => void;
};

export type EmployeeDetails = {
  firstName: string;
  lastName: string;
  email: string;
  department: Dept;
  role: string;
};

type Dept = {
  label: string;
  value: string;
};

export default function AddNewEmployee({
  onCancel,
  onSuccess,
  selectedEmployee,
  setEmployeeDetails,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const { data, isSuccess, isLoading } = useGetPermissionList();
  const { docs } = useAppSelector(selectBusiness);
  const { data: departments } = useGetDepartment({
    businessId: docs[0].businessID._id,
    page: 1,
  });
  const { data: roles } = useGetRole({ businessId: docs[0].businessID._id, page: 1 });

  const { mutate, isSuccess: isSaved, isError } = useCreateEmployee();
  const {
    mutate: update,
    isSuccess: isUpdated,
    isError: isUpdateError,
  } = useUpdateEmployee();

  const formik = useFormik({
    initialValues: {
      firstName: selectedEmployee?.firstName ?? '',
      lastName: selectedEmployee?.lastName ?? '',
      email: selectedEmployee?.email ?? '',
      department: {
        value: selectedEmployee?.department?._id,
        label: selectedEmployee?.department?.name,
      },
      role: selectedEmployee?.role ?? '',
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required('First Name is required').nullable(),
      lastName: Yup.string().required('Last Name is required').nullable(),
      email: Yup.string().email('Invalid Email').required('Email is required').nullable(),
      department: Yup.object()
        .shape({
          label: Yup.string(),
          value: Yup.string(),
        })
        .required('Department is required')
        .nullable(),
      role: Yup.string().required('Role is required').nullable(),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      if (selectedEmployee) {
        update({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          department: values.department.value ?? '',
          role: values.role,
          businessId: docs[0].businessID._id,
          employeeId: selectedEmployee._id,
        });
      } else {
        mutate({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          department: values.department.value ?? '',
          role: values.role,
          businessId: docs[0].businessID._id,
        });
      }
    },
  });

  useEffect(() => {
    if (isSaved && !isError) {
      toast.success('Employee successfully created and invite has been sent');
      if (setEmployeeDetails) {
        const newEmployee: EmployeeDetails = {
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: formik.values.email,
          department: {
            value: formik.values.department.value ? formik.values.department.value : '',
            label: formik.values.department.label ? formik.values.department.label : '',
          },
          role: formik.values.role,
        };
        setEmployeeDetails((prev: any) => [...prev, newEmployee]);
      }
      setIsSubmitting(false);
      formik.setSubmitting(false);
      onSuccess();
    } else if (isError) {
      setIsSubmitting(false);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSaved]);

  useEffect(() => {
    if (isUpdated && !isUpdateError) {
      toast.success('Employee successfully updated');
      setIsSubmitting(false);
      formik.setSubmitting(false);
      onSuccess();
    } else if (isUpdateError) {
      setIsSubmitting(false);
      formik.setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateError, isUpdated]);

  return (
    <InfoModal width="w-[50%]" className="h-fit " onClose={onCancel}>
      <div className="mr-12 rounded-[20px] bg-white p-8 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col">
          <div className="py-5 pb-3 text-left border-b max-w-xss">
            <p className="text-xl font-medium text-black font-inter">Add Employee</p>
            <p className="text-sm font-normal font-inter text-black/40">
              Invite persons to be contributors
            </p>
          </div>

          <div className="w-full mx-auto my-8">
            <form className="space-y-0.5" onSubmit={formik.handleSubmit}>
              <div className="grid flex-1 grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="firstName"
                  type="text"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="First Name"
                  error={formik.touched.firstName ? formik.errors.firstName : ''}
                  className="bg-white"
                  disabled={!!selectedEmployee}
                />
                <TextField
                  name="lastName"
                  type="text"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Last Name"
                  error={formik.touched.lastName ? formik.errors.lastName : ''}
                  className="bg-white"
                />
              </div>
              <div className="grid flex-1 grid-cols-1 gap-x-5 gap-y-2 sm:grid-cols-2">
                <TextField
                  name="email"
                  type="text"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Email"
                  error={formik.touched.email ? formik.errors.email : ''}
                  className="bg-white"
                />
                <CustomSelect
                  options={(departments?.docs ?? []).map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  selectedOption={{
                    value: formik.values.department?.value ?? '',
                    label: formik.values.department?.label ?? '',
                  }}
                  handleOptionChange={(option) => {
                    formik.setFieldValue('department', option);
                  }}
                  label="Department"
                  name="department"
                  isSearchable
                  className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                  error={formik.errors.department ? 'Department is required' : ''}
                />
              </div>
              <CustomSelect
                options={(roles?.docs ?? []).map((item) => ({
                  value: item.name,
                  label: item.name,
                }))}
                selectedOption={{
                  value: formik.values.role,
                  label: formik.values.role,
                }}
                handleOptionChange={(option) => {
                  formik.setFieldValue('role', option?.value);
                }}
                label="Role"
                name="role"
                isSearchable
                className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                error={formik.touched.role ? formik.errors.role : ''}
              />
              <div>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  label={selectedEmployee ? 'Edit Employee' : 'Invite'}
                  className="mt-12"
                  loading={isSubmitting}
                  disabled={isSubmitting || docs[0].businessID.status === 'pending'}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
