import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextField from '../../Form/TextField';
import CustomSelect from '../../Form/CustomSelect';
import Button from '../../Form/Button';
import { InfoModal } from '../InfoModal';
import {
  useCreateProduct,
  useUpdateProduct,
} from '../../../hooks/queries-and-mutations/product';
import { ProductDoc } from '../../../types/product.type';

type Props = {
  onCancel: () => void;
  onSuccess: () => void;
  businessId: string;
  selectedProduct: ProductDoc;
};

export default function AddProduct({
  onCancel,
  onSuccess,
  businessId,
  selectedProduct,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { mutate, isSuccess, isError } = useCreateProduct();
  const {
    mutate: update,
    isSuccess: isUpdate,
    isError: isUpdateError,
  } = useUpdateProduct();

  const formik = useFormik({
    initialValues: {
      name: selectedProduct?.name ?? '',
      currency: selectedProduct?.currency ?? '',
      price: selectedProduct?.price ?? '',
      description: selectedProduct?.description ?? '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Product name is required').nullable(),
      currency: Yup.string().required('Product currency is required').nullable(),
      price: Yup.string().required('Product price is required').nullable(),
      description: Yup.string().required('Product description is required').nullable(),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      setIsSubmitting(true);
      if (selectedProduct) {
        update({ businessId, productId: selectedProduct?._id, payload: values });
      } else {
        mutate({ businessId, payload: values });
      }
    },
  });

  const handleWheel = (event: any) => {
    event.target.blur();
  };

  useEffect(() => {
    if (isSuccess && !isError) {
      setIsSubmitting(false);
      onSuccess();
      formik.resetForm();
    } else if (isError) {
      onSuccess();
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess]);

  useEffect(() => {
    if (isUpdate && !isUpdateError) {
      setIsSubmitting(false);
      onSuccess();
      formik.resetForm();
    } else if (isUpdateError) {
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdateError, isUpdate]);

  return (
    <InfoModal width="w-[45%]" className="h-fit " onClose={onCancel}>
      <div className="rounded-[20px] bg-white p-8 font-circular text-2xl font-normal text-[#353535]">
        <div className="flex flex-col">
          <div className="py-5 pb-3 text-left border-b max-w-xss">
            <p className="text-xl font-medium text-black font-inter">
              {selectedProduct ? 'Edit Product' : 'Add Product'}
            </p>
            <p className="text-sm font-normal font-inter text-black/40">
              {selectedProduct ? 'Edit Product' : 'Add a new product'}
            </p>
          </div>

          <div className="w-full mx-auto my-8">
            <form className="space-y-0.5" onSubmit={formik.handleSubmit}>
              <TextField
                name="name"
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleChange}
                label="Product Name"
                error={formik.touched.name ? formik.errors.name : ''}
                className="bg-white"
              />
              <div className="grid flex-1 grid-cols-1 pt-5 gap-x-5 gap-y-2 sm:grid-cols-2">
                <CustomSelect
                  options={[
                    { value: 'NGN', label: 'NGN' },
                    { value: 'USD', label: 'USD' },
                  ]}
                  selectedOption={{
                    value: formik.values.currency,
                    label: formik.values.currency,
                  }}
                  handleOptionChange={(option) => {
                    formik.setFieldValue('currency', option?.value);
                  }}
                  label="Product Currency"
                  name="currency"
                  isSearchable
                  className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                  error={formik.touched.currency ? formik.errors.currency : ''}
                />
                <TextField
                  name="price"
                  type="number"
                  step="0.01"
                  onWheel={handleWheel}
                  min={0}
                  value={formik.values.price}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  label="Product Price"
                  error={formik.touched.price ? formik.errors.price : ''}
                  className="bg-white"
                />
              </div>
              <div className="pt-6">
                <label
                  htmlFor="description"
                  className="font-medium font-inter text-sm text-[#000000]"
                >
                  Product description
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows={5}
                  onChange={formik.handleChange}
                  onBlur={formik.handleChange}
                  className={`form-textarea text-sm font-inter font-normal rounded-lg  
                      ${
                        formik.touched.description && formik.errors.description
                          ? 'bg-red-50 border border-red-500 text-red-900 placeholder:text-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500'
                          : 'bg-gray-50 border border-[#0000001A] placeholder:text-white-dark '
                      }`}
                  defaultValue={formik.values.description}
                />
                {formik.errors.description && (
                  <p className="mt-2 text-xs text-red-600 capitalize">
                    {formik.errors.description}
                  </p>
                )}
              </div>

              <div>
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  label={selectedProduct ? 'Update' : 'Submit'}
                  className="mt-12"
                  loading={formik.isSubmitting}
                  disabled={formik.isSubmitting || isSubmitting}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </InfoModal>
  );
}
