import { format } from 'date-fns';
import { ApprovalTypeDoc, GroupedData } from '../types/approval.type';

export const determinePathName = (pathname: string) => {
  if (['/settings', '/profile', '/add-account'].includes(pathname)) {
    return '/settings';
  }
  const parts = pathname.split('/');
  const accountIndex = parts[2] || [1];
  return accountIndex;
};

export const createWrapperAndAppendToBody = (wrapperId: string) => {
  const wrapperElement = document.createElement('div');
  wrapperElement.setAttribute('id', wrapperId);
  document.body.appendChild(wrapperElement);
  return wrapperElement;
};

export const isEmptyFile = (object: object): boolean => {
  if (object) {
    return Object.keys(object).length === 0;
  }
  return false;
};

export const formatCurrency = (currency = 'NGN', amount: any) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });
  return formatter.format(amount || 0);
};

export const getDateStatus = (targetDate: string) => {
  const today = new Date();
  const target = new Date(targetDate);
  const timeDiff = target.getTime() - today.getTime();
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

  if (daysDiff > 0) {
    return `${daysDiff} days left`;
  } else if (daysDiff < 0) {
    return `${Math.abs(daysDiff)} days overdue`;
  } else {
    return 'Due today';
  }
};

export const canShowPaymentHistory = (status: string) => {
  return (
    status === 'approved_for_financing' ||
    status === 'outstanding' ||
    status === 'awaiting_customer_approval' ||
    status === 'paid'
  );
};

export const getMonthName = (month: number): string => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  return months[month - 1];
};

export const groupDataByLevel = (docs: ApprovalTypeDoc[]): GroupedData => {
  // const groupedData: GroupedData = {};

  // for (const doc of docs) {
  //   const payableLevel = doc.approvals.payable.level;
  //   const receivableLevel = doc.approvals.receivable.level;
  //   const vendorLevel = doc.approvals.vendor.level;

  //   if (payableLevel > 0 || receivableLevel > 0 || vendorLevel > 0) {
  //     const levelName = getLevelName(Math.max(payableLevel, receivableLevel, vendorLevel));

  //     if (!groupedData[levelName]) {
  //       groupedData[levelName] = { payable: [], receivable: [], vendor: [] };
  //     }

  //     if (doc.approvals.payable.level > 0) {
  //       groupedData[levelName].payable.push(doc);
  //     }

  //     if (doc.approvals.receivable.level > 0) {
  //       groupedData[levelName].receivable.push(doc);
  //     }

  //     if (doc.approvals.vendor.level > 0) {
  //       groupedData[levelName].vendor.push(doc);
  //     }
  //   }

  // }

  const groupedData: GroupedData = { payable: {}, receivable: {}, vendor: {} };

  for (const doc of docs) {
    const payableLevel = doc.approvals.payable.level;
    const receivableLevel = doc.approvals.receivable.level;
    const vendorLevel = doc.approvals.vendor.level;

    if (payableLevel > 0) {
      // const levelName = getLevelName(payableLevel);
      groupedData.payable[payableLevel] = groupedData.payable[payableLevel] || [];
      groupedData.payable[payableLevel].push({ ...doc, level: payableLevel });
    }

    if (receivableLevel > 0) {
      // const levelName = getLevelName(receivableLevel);
      groupedData.receivable[receivableLevel] =
        groupedData.receivable[receivableLevel] || [];
      groupedData.receivable[receivableLevel].push({
        ...doc,
        level: receivableLevel,
      });
    }

    if (vendorLevel > 0) {
      // const levelName = getLevelName(vendorLevel);
      groupedData.vendor[vendorLevel] = groupedData.vendor[vendorLevel] || [];
      groupedData.vendor[vendorLevel].push({ ...doc, level: vendorLevel });
    }
  }

  return groupedData;
};

export const getLevelName = (number: number) => {
  if (typeof number !== 'number' || isNaN(number) || number < 1) {
    return 'Invalid input';
  }

  const words = [
    'First level approval',
    'Second level approval',
    'Third level approval',
    'Fourth level approval',
    'Fifth level approval',
    'Sixth level approval',
    'Seventh level approval',
    'Eighth level approval',
    'Ninth level approval',
    'Tenth level approval',
  ];

  if (number <= words.length) {
    return words[number - 1];
  } else {
    return number + 'th'; // fallback for numbers greater than the length of the words array
  }
};

export const formatDate = (date: string | number | Date) => {
  if (!date) return '';
  const formattedDate = format(new Date(date), 'yyyy-MM-dd');
  return isNaN(new Date(formattedDate).getTime()) ? '' : formattedDate;
};
