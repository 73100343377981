import React from 'react';
import { InfoModal } from './InfoModal';
import logo from '../../Assests/newLogos/WordmarkThree.png';
import { BusinessId } from '../../types/business.type';
import { BillingHistory } from '../../types/subscription.type';
import { format } from 'date-fns';
import { formatCurrency } from '../../utils';

type Props = {
  business: BusinessId;
  billing: BillingHistory;
  onCancel: () => void;
};

export default function SubscriptionInvoiceModal({ business, billing, onCancel }: Props) {
  return (
    <div>
      <InfoModal width="w-[50%]" className="h-fit " onClose={onCancel} usePadding={false}>
        <div className="py-8">
          <div className="flex items-center justify-between w-[90%]">
            <div className="w-[200px]">
              <img src={logo} alt="logo" />
            </div>
            <p className="text-xl font-medium font-inter text-black">Invoice</p>
          </div>
          <div className="px-12 flex flex-col flex-wrap justify-between gap-6 lg:flex-row mt-14">
            <div className="flex-1">
              <div className="space-y-1 text-black/70 text-base font-medium">
                <div className="font-semibold text-black">Invoice to</div>
                <div>{business.name}</div>
                <div>
                  {business.addresses[0].address} {business.addresses[0].state}
                </div>
                <div>{business.email}</div>
                <div>{business.phone}</div>
              </div>
            </div>
            <div className="px-4 text-right">
              <div className="space-y-1 text-white-dark">
                <div className="font-semibold text-black/70">Invoice details</div>
                <div>Invoice no: {billing.payment_ref}</div>
                <div>Date: {format(new Date(billing.date), 'dd, MMM yyyy')}</div>
                <div>Due date: {format(new Date(billing.due_date), 'dd, MMM yyyy')}</div>
                {/* <div>Terms of trade: missing from api</div> */}
              </div>
            </div>
          </div>
          {/* Table */}
          <div className="mt-6">
            <table>
              <thead className="bg-[#0353A4B0] text-white rounded mb-3">
                <tr>
                  <th>Plan</th>
                  <th>Price/Seat</th>
                  <th>Number of seat</th>
                  <th>Total per month</th>
                </tr>
              </thead>
              <tbody className="bg-[#B9D6F224]">
                <tr>
                  <td className="whitespace-nowrap">{billing.plan} </td>
                  <td className="whitespace-nowrap">missing from api</td>
                  <td className="whitespace-nowrap">{billing.seat_subbed}</td>
                  <td className="whitespace-nowrap">missing from api</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="border-b mx-10 mb-10 pb-10 flex items-center justify-end mt-9 px-10">
            {/* Remove s in the width */}
            <div className="sm:w-2/5s">
              <div className="flex items-center justify-between">
                <div>Subtotal</div>
                <div>{formatCurrency(billing?.currency, billing?.sub_total ?? 0)}</div>
              </div>
              <div className="mt-4 flex items-center justify-between">
                <div>Discount</div>
                <div>{formatCurrency(billing?.currency, billing?.discount ?? 0)}</div>
              </div>
              <div className="mt-4 flex items-center justify-between">
                <div>Tax</div>
                <div>{formatCurrency(billing?.currency, billing?.tax ?? 0)}</div>
              </div>
              <div className="mt-4 py-6 rounded-2xl bg-[#B9D6F2] p-4 flex items-center justify-between font-semibold">
                <div>Amount Due</div>
                <h2 className="ml-1">
                  {formatCurrency(billing?.currency, billing?.amount)}
                </h2>
              </div>
            </div>
          </div>

          <div className="px-10 flex items-center justify-between">
            <div className="font-medium font-inter text-base text-black">
              <p className="font-semibold">Note</p>
              Missing from the API
            </div>
          </div>
        </div>
      </InfoModal>
    </div>
  );
}
