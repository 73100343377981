import React from 'react';
import { InlineWidget } from 'react-calendly';

const CalendlyBooking = () => {
  return (
    <div className="w-full h-full">
      <InlineWidget url="https://calendly.com/paytton-support/demo" />
    </div>
  );
};

export default CalendlyBooking;
