import React, { useRef, useState } from 'react';
import { InvoiceDoc, InvoiceStatus } from '../../../types/invoice.type';
import { useNavigate } from 'react-router-dom';
import { deleteAnInvoice, markInvoice } from '../../../service/invoice';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import withCreatePortal from '../../Hoc/withCreatePortal';
import ConfirmPaymentModal from '../ConfirmPaymentModal';
import ProofModal from '../ProofModal';
import DeleteModal from '../DeleteModal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { motion } from 'framer-motion';
import useOnClickOutside from '../../../CustomHooks/useClickOutside';

interface IProps {
  className?: string;
  onClick?: () => void;
  invoice?: InvoiceDoc | null;
  businessId?: string;
  name?: string;
  email: string;
  setInvoiceData: (arg: any) => void;
  visibility: string;
  setActiveIndex: (arg: any) => void;
  filterValue?: string;
}

const EnhancedConfirmModal = withCreatePortal(ConfirmPaymentModal);
const EnhancedReceiptModal = withCreatePortal(ProofModal);
const EnhancedDeleteModal = withCreatePortal(DeleteModal);

const Action: React.FC<IProps> = ({
  invoice,
  businessId,
  setInvoiceData,
  visibility,
  email,
  setActiveIndex,
}) => {
  const navigate = useNavigate();
  const [confirmationModalActive, setConfirmationModalActive] = useState<boolean>(false);
  const [proceedWithPayment, setProceedWithPayment] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [markInvoiceModal, setMarkInvoiceModal] = useState<boolean>(false);
  const [deleteConfirmation, setDeleteConfirmatiion] = useState(false);
  const modalRef = useRef(null);

  const deleteInvoiceId = async (businessId: any, invoiceId: string | undefined) => {
    await deleteAnInvoice(businessId, (invoiceId && invoiceId) || '')
      .then(() => {
        toast.success('InvoiceId  deleted successfully');
        setInvoiceData((prev: any) => ({
          ...prev,
          docs: prev.docs.filter((res: any) => res?._id !== invoiceId),
        }));
        setActiveIndex('');
      })
      .catch((error) => {
        const err = error as AxiosError<Error>;
        toast.error(err.message ?? 'Sorry, An error as occurred');
      });
  };

  //! This is temporal until it's decided
  // const sendARemainder = async (businessId: any, invoiceId: string | undefined) => {
  //   const payload = {
  //     email: `${invoice?.email}`,
  //     cc: [
  //       {
  //         email: `${email}`,
  //       },
  //     ],
  //     message: `This is a reminder your Invoice ${invoice?.number} sent to ${name} is now overdue. Log-in to view the invoice and send a reminder. You can also confirm payment if you have received payment for this invoice. Thank you, Paytton `,
  //   };
  //   if (invoiceId) {
  //     await sendRemainder(businessId, invoiceId, payload)
  //       .then(() => {
  //         toast.success('A remainder has been sent successfully');
  //       })
  //       .catch((error) => {
  //         const err = error as AxiosError<Error>;
  //         toast.error(err.message ?? 'Sorry, An error as occurred');
  //       });
  //   }
  // };

  const formik = useFormik({
    initialValues: {
      receipt: null,
    },
    validationSchema: Yup.object({
      receipt: Yup.mixed().required().nullable(),
    }),
    onSubmit: async (values) => {
      if (values.receipt && businessId && invoice?._id) {
        const formData = new FormData();
        formData.append('proof', values.receipt);
        await markInvoice(businessId, invoice?._id, formData)
          .then(() => {
            toast.success('Invoice marked as paid successful');
          })
          .catch((error) => {
            const err = error as AxiosError<Error>;
            toast.error(err.message ?? 'Sorry!, An error as occurred');
          });
      }
    },
  });

  const handleModalClose = () => {
    setActiveIndex(null);
  };

  useOnClickOutside(
    confirmationModalActive || markInvoiceModal ? modalRef.current : modalRef,
    handleModalClose
  );

  return (
    <div>
      <motion.div
        ref={modalRef}
        className={`${confirmationModalActive && 'opacity-0'} ${
          visibility === InvoiceStatus.DRAFT
            ? 'absolute left-[-70px] bottom-[-190px] z-5 flex flex-col justify-center items-center panel bg-white w-[165px] '
            : visibility === InvoiceStatus.APPROVED
            ? 'absolute left-[-80px] bottom-[-170px] z-5 flex flex-col justify-center items-center panel  bg-white w-[165px]'
            : visibility === InvoiceStatus.AWAITING_APPROVAL_BUYER ||
              visibility === InvoiceStatus.AWAITING_APPROVAL_SELLER ||
              visibility === InvoiceStatus.SELLER_INTERNAL_APPROVED
            ? 'absolute left-[-70px] bottom-[-50px] z-5 flex flex-col justify-center items-center panel bg-white w-[165px]'
            : 'absolute left-[-90px] bottom-[-70px] z-5 flex flex-col justify-center items-center panel bg-white w-[165px]'
        } `}
        initial={{ opacity: 0, height: 0, y: -100 }}
        animate={{
          position: 'absolute',
          opacity: confirmationModalActive || markInvoiceModal ? 0 : 1,
          height: 'auto',
          y: 0,
          zIndex: 60,
          backgroundColor: 'white',
        }}
        exit={{ opacity: 0, height: 0, y: -100 }}
      >
        <ul className=" flex flex-col gap-3 w-[100%]">
          <li
            onClick={() => {
              navigate(`/invoices/view/${invoice?._id}`);
            }}
            className="py-4 hover:bg-primary hover:text-white transition duration-1000 ease-out cursor-pointer"
          >
            {' '}
            <button className="px-4 "> View </button>
          </li>
          {visibility === InvoiceStatus.draft && (
            <li
              onClick={() => {
                navigate(`/invoices/create`);
              }}
              className="py-4 hover:bg-primary hover:text-white transition duration-1000 ease-out"
            >
              <button className="px-4">Edit</button>{' '}
            </li>
          )}

          {/* {visibility === InvoiceStatus.AWAITING_APPROVAL_SELLER ||
          visibility === InvoiceStatus.AWAITING_APPROVAL_BUYER ? (
            <li className="py-1 hover:bg-hoverBgColor  hover:text-black transition duration-1000 ease-out">
              <button
                className="px-4 py-1"
                onClick={() => sendARemainder(businessId, invoice?._id && invoice._id)}
              >
                Send reminder{' '}
              </button>{' '}
            </li>
          ) : (
            ''
          )} */}
          {visibility === InvoiceStatus.APPROVED && (
            <li className="py-4 hover:bg-primary hover:text-white transition duration-1000 ease-out">
              <button
                className="px-4 "
                onClick={() => {
                  setMarkInvoiceModal(true);
                }}
              >
                Mark as paid{' '}
              </button>{' '}
            </li>
          )}
          {visibility === InvoiceStatus.APPROVED && (
            <li className="py-4 relative z-40 hover:bg-primary hover:text-white transition duration-1000 ease-out">
              <button
                onClick={() => {
                  setConfirmationModalActive(true);
                }}
                className="px-4"
              >
                Make payment{' '}
              </button>{' '}
            </li>
          )}
          {visibility === InvoiceStatus.draft && (
            <li className="py-4 hover:bg-primary hover:text-white transition duration-1000 ease-out">
              <button
                onClick={() => {
                  setDeleteConfirmatiion(true);
                }}
                className="px-4"
              >
                Delete{' '}
              </button>{' '}
            </li>
          )}
        </ul>
      </motion.div>
      {deleteConfirmation && (
        <EnhancedDeleteModal
          title="Delete An Invoice"
          content={`This Invoice for ${invoice?.sellerName} would be deleted `}
          invoiceName={invoice?.sellerName}
          actionText="Delete"
          onCancel={() => {
            setDeleteConfirmatiion(false);
          }}
          onConfirm={() => {
            deleteInvoiceId(businessId, invoice?._id);
          }}
          cancelText="Cancel"
          cancelClassName="w-[200px] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-full min-w-fit px-3 py-3 text-base bg-[#061A40] rounded font-normal text-center text-white"
        />
      )}
      {confirmationModalActive && (
        <EnhancedConfirmModal
          title="Make a payment"
          content={`Payment would be made to `}
          invoiceName={invoice?.sellerName}
          actionText="Proceed with Payment"
          onCancel={() => {
            setConfirmationModalActive(false);
            setProceedWithPayment(false);
          }}
          cancelText="Cancel"
          cancelClassName="w-[200px] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-full min-w-fit px-3 py-3 text-base bg-[#061A40] rounded font-normal text-center text-white"
          onPaymentClose={() => {
            toast.error('Payment closed');
          }}
          businessId={businessId}
          invoiceId={invoice?._id}
          email={email}
          amount={invoice?.amount}
          currency={invoice?.currency}
          continuePayment={proceedWithPayment}
          onConfirm={() => {
            setProceedWithPayment(true);
          }}
          onBack={() => {
            setProceedWithPayment(false);
          }}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
        />
      )}
      {markInvoiceModal && (
        <EnhancedReceiptModal
          title="Submit a Receipt"
          invoiceName={invoice?.sellerName}
          actionText="Submit Receipt"
          onCancel={() => {
            setMarkInvoiceModal(false);
          }}
          onConfirm={() => {
            formik.handleSubmit();
          }}
          cancelText="Cancel"
          cancelClassName="w-[200px] border border-[#000000] rounded bg-transparent text-[#061A40] px-5 py-2.5 text-sm font-normal"
          actionClassName="w-full px-3 py-3 text-base bg-[#061A40] rounded font-normal text-white"
          onPaymentClose={() => {
            toast.error('Payment close');
          }}
          businessId={businessId}
          invoiceId={invoice?._id}
          formik={formik}
        />
      )}
    </div>
  );
};

export default Action;
