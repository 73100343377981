import React from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  toggleMenu: (val: boolean) => void;
};

export default function MobileMenu({ toggleMenu }: Props) {
  return (
    <div className="lg:hidden md:hidden">
      <div className="hs-collapse overflow-hidden transition-all duration-300 lg:basis-auto basis-full grow lg:flex items-center justify-center mx-auto mt-2 lg:mt-0 open">
        <ul className="navbar-landing-nav space-y-5">
          {/* Home Page Link */}
          <li className="nav-item" onClick={() => toggleMenu(false)}>
            <a className="nav-link" href="#about">
              About
            </a>
          </li>
          {/* Features Page Link */}
          <li className="nav-item">
            <a className="nav-link" href="#feature" onClick={() => toggleMenu(false)}>
              Features
            </a>
          </li>
          {/* Pricing Page Link */}
          <li className="nav-item">
            <a className="nav-link" href="#pricing" onClick={() => toggleMenu(false)}>
              Pricing
            </a>
          </li>
          {/* Faq Page Link */}
          <li className="nav-item">
            <a className="nav-link" href="#faq" onClick={() => toggleMenu(false)}>
              Faq
            </a>
          </li>
        </ul>
      </div>

      <div className="lg:hidden flex items-center gap-5 py-3">
        <NavLink
          to="/auth/login"
          className="relative flex items-center justify-center rounded-[9.79px] text-base font-medium py-2 px-5 mt-10 mx-auto bg-[#061A40] text-white"
        >
          Sign in
        </NavLink>
      </div>
    </div>
  );
}
