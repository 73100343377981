import { useCallback, useEffect, useMemo, useState } from 'react';
import Breadcrumb from '../../../../Components/Breadcrumb';
import TextField from '../../../../Components/Form/TextField';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import CustomSelect from '../../../../Components/Form/CustomSelect';
import { Icon, Icons } from '../../../../Components/Icon';
import Button from '../../../../Components/Form/Button';
import InvoicePreview from './InvoicePreview';
import { getVendors } from '../../../../service/vendor';
import { useAppSelector } from '../../../../store/hooks';
import { selectBusiness } from '../../../../selectors/business-selector';
import { VendorObject } from '../../../../types/vendor.type';
import { getProducts } from '../../../../service/product';
import withCreatePortal from '../../../../Components/Hoc/withCreatePortal';
import AddProduct from '../../../../Components/Modal/Product/AddProduct';
import AddVendorModal from '../../../../Components/Modal/Vendor/AddVendorModal';
import StateData from '../../../../Data/StateData';
import { formatCurrency } from '../../../../utils';
import { InvoiceDto } from '../../../../types/invoice.type';
import { useGlobalContext } from '../../../../Context/Context';
import axios from 'axios';
import { getToken } from '../../../../service';
import { toast } from 'react-toastify';
const EnhancedAddProductModal = withCreatePortal(AddProduct);
const EnhancedAddVendorModal = withCreatePortal(AddVendorModal);

export default function CreateInvoice() {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [showDiscountOption, setShowDiscountOption] = useState<boolean>(false);
  const [showTaxOption, setShowTaxOption] = useState<boolean>(false);
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [vendorsData, setVendorsData] = useState<VendorObject[]>();
  const [productsData, setProductsData] = useState<VendorObject[]>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [notRegistered, setNotRegistered] = useState<boolean>(false);
  const [selectedVendor, setSelectedVendor] = useState<any>([]);
  const [showAddProductModal, setShowAddProductModal] = useState<boolean>(false);
  const [showAddVendorModal, setShowAddVendorModal] = useState<boolean>(false);
  const { docs } = useAppSelector(selectBusiness);
  const { selectedInvoiceId, setSelectedInvoiceId } = useGlobalContext();

  const fetchVendorsData = useCallback(
    async (currentPage: number, filterBy?: string) => {
      // Fetch vendors from the API
      const vendors = await getVendors(
        docs[0].businessID?._id,
        currentPage,
        filterBy,
        1000,
        null,
        null
      );

      // Filter out vendors that are not blacklisted
      const nonBlacklistedVendors = vendors.docs.filter(
        (vendor: any) => !vendor.blacklisted
      );

      // Transform the data into options format
      const transformedOptions = nonBlacklistedVendors
        .filter((item: any) => item.type !== 'supplier')
        .filter((item: any) => item.vendorID !== null)
        .map((option: any) => ({
          label: option.vendorName,
          value: option._id,
          ...option,
        }));
      // Set the transformed options in state
      setVendorsData(transformedOptions);
    },
    [docs]
  );

  // const formatDate = (date: string | number | Date) => {
  //   if (!date) return ''; // Handle empty date
  //   const formattedDate = format(new Date(date), 'dd, MMM yyyy');
  //   return isNaN(new Date(formattedDate).getTime()) ? '' : formattedDate;
  // };

  // const handleDueDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const selectedDate = new Date(event.target.value);
  //   const currentDate = new Date();
  //   if (selectedDate < currentDate) {
  //     setDueDateError(true);
  //   } else {
  //     setDueDateError(false);
  //     setDueDate(event.target.value);
  //   }
  // };

  const fetchProductsData = useCallback(
    async (currentPage: number) => {
      const products = await getProducts(docs[0].businessID._id, currentPage);
      const transformedOptions = products.docs.map((option: any) => ({
        label: option.name,
        value: option._id,
        ...option,
      }));
      setProductsData(transformedOptions);
    },
    [docs]
  );

  const formik = useFormik({
    initialValues: {
      invoiceNumber: '',
      date: new Date().toISOString().split('T')[0],
      dueDate: '',
      currency: 'NGN',
      discountType: 'percentage',
      discount: 0,
      taxType: 'percentage',
      tax: 0,
      vendorName: '',
      vendorAddress: '',
      vendorCountry: '',
      vendorCity: '',
      products: [
        {
          product: { value: '', label: '' },
          productDescription: '',
          price: 0,
          quantity: 1,
          amount: 0,
        },
      ],
      note: '',
    },
    validationSchema: Yup.object({
      invoiceNumber: Yup.string().required('Invoice Number is required').nullable(),
      date: Yup.date().required('Date is required').nullable(),
      dueDate: Yup.string().required('Due Date is required').nullable(),
      currency: Yup.string().required('Currency is required').nullable(),
      discountType: Yup.string().nullable(),
      discount: Yup.number().nullable(),
      tax: Yup.string().nullable(),
      vendorName: Yup.string().required('Vendor name is required').nullable(),
      vendorCountry: Yup.string().required('Country is required').nullable(),
      vendorCity: Yup.string().required('City is required').nullable(),
      products: Yup.array().of(
        Yup.object().shape({
          product: Yup.object()
            .shape({
              label: Yup.string(),
              value: Yup.string(),
            })
            .nullable()
            .required('Please select a product'),
          productDescription: Yup.string().required('ID Number is required').nullable(),
          price: Yup.number().required('Attach ID is required').nullable(),
          quantity: Yup.number().required('Attach ID is required').nullable(),
          amount: Yup.number().required('Attach ID is required').nullable(),
        })
      ),
      note: Yup.string(),
    }),
    onSubmit: () => {
      setIsSubmitting(true);
    },
  });

  useEffect(() => {
    fetchVendorsData(1);
    fetchProductsData(1);
    if (docs[0].businessID?.lastInvoiceNumber) {
      formik.setFieldValue(
        'invoiceNumber',
        `Invoice#0000${docs && docs[0]?.businessID?.lastInvoiceNumber + 1}`
      );
    } else if (docs[0].businessID?.lastInvoiceNumber === 0) {
      formik.setFieldValue('invoiceNumber', `Invoice#000001`);
    } else {
      formik.setFieldValue(
        'invoiceNumber',
        `Invoice#00000${
          docs[0].businessID?.lastInvoiceNumber &&
          docs[0].businessID?.lastInvoiceNumber + 1
        }`
      );
    }
  }, []);

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Returns 'yyyy-MM-dd'
  };

  useEffect(() => {
    getSavedInvoice();
  }, []);

  const getSavedInvoice = async () => {
    if (selectedInvoiceId) {
      try {
        const response = await axios({
          url: `${process.env.REACT_APP_API_URL}/invoice/${docs[0].businessID?._id}/${selectedInvoiceId}/fetch`,
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        });

        if (response.data) {
          const invoiceData = response.data.data.invoice;
          const mappedProducts = invoiceData.products.map((item: any) => ({
            product: {
              currency: item.product?.currency,
              label: item.product?.name,
              value: item.product?._id,
            },
            productDescription: item.product?.description,
            price: item?.product?.price,
            quantity: item?.quantity,
            amount: item?.quantity,
          }));
          formik.setValues({
            invoiceNumber: invoiceData.number,
            date: formatDate(invoiceData.date),
            dueDate: formatDate(invoiceData.requiredEnd),
            currency: invoiceData.currency,
            discountType: invoiceData.discount.type
              ? invoiceData.discount.type
              : 'percentage',
            discount: invoiceData.discount.value,
            taxType: invoiceData.tax.type ? invoiceData.tax.type : 'percentage',
            tax: invoiceData.tax.value,
            vendorName: invoiceData?.sellerName || invoiceData?.vendorName,
            vendorAddress: invoiceData.address,
            vendorCountry:
              invoiceData.country?.length > 1 ? invoiceData.country : 'Nigeria',
            vendorCity: invoiceData.state,
            products: mappedProducts,
            note: invoiceData.note,
          });
          setSelectedVendor(invoiceData);
          const selectedVendor =
            vendorsData &&
            vendorsData.find((vendor) => vendor.label === invoiceData?.sellerName);

          if (selectedVendor) {
            formik.setFieldValue('vendorName', selectedVendor?.value); // Set the vendor ID
          }
        }
      } catch (err) {
        toast.error('An  error occurred while fetching the invoice.');
      }
    }
  };

  const prefillVendor = (e: any) => {
    setSelectedVendor(e);
    if (e.active) {
      formik.setFieldValue('vendorAddress', e.vendorID.addresses[0].address);
      formik.setFieldValue('vendorCity', e.vendorID.addresses[0].state);
      formik.setFieldValue('vendorCountry', e.vendorID.addresses[0].country);
      formik.setFieldValue('vendorName', e.vendorName);
      setNotRegistered(false);
    } else {
      formik.setFieldValue('vendorAddress', '');
      formik.setFieldValue('vendorCity', '');
      formik.setFieldValue('vendorCountry', '');
      formik.setFieldValue('vendorName', '');
      setNotRegistered(true);
    }
  };

  const addMoreProduct = useCallback(() => {
    formik.values.products.push({
      product: { value: '', label: '' },
      productDescription: '',
      price: 0,
      quantity: 0,
      amount: 0,
    });
    formik.setFieldValue('products', [...formik.values.products]);
  }, [formik]);

  const removeProduct = useCallback(
    (indexToRemove: number) => {
      formik.values.products.splice(indexToRemove, 1);
      formik.setFieldValue('products', [...formik.values.products]);
    },
    [formik]
  );

  const onPreview = () => {
    setShowPreview(true);
  };

  // const onReturnFromPreview = () => {
  //   setShowPreview(false);
  // };

  const calculateAmount = (price: number, quantity: number) => {
    return price * quantity;
  };

  const handleAddProduct = (value: any, index: number) => {
    if (value) {
      const existingProductIndex = formik.values.products.findIndex(
        (product: any) => product.product.value === value.value
      );

      if (existingProductIndex !== -1) {
        const existingProduct = formik.values.products[existingProductIndex];
        existingProduct.quantity = 1 + Number(existingProduct.quantity);
        const newQuantityValue = Number(existingProduct.quantity);

        existingProduct.amount = existingProduct.price * newQuantityValue;

        const updatedProducts = [
          ...formik.values.products.filter((f: any) => f.price !== 0),
        ];

        updatedProducts[existingProductIndex] = { ...existingProduct };

        formik.setFieldValue('products', updatedProducts);
      } else {
        formik.setFieldValue(`products[${index}]`, {
          product: { value: value.value, label: value.label },
          productDescription: value.description,
          price: value.price,
          quantity: 1,
          amount: value.price,
        });
      }
    }
  };

  // const editProductQuantity = (productID: string, newQuantity: number) => {
  //   const existingProductIndex = formik.values.products.findIndex(
  //     (product: any) => product.product.value === productID
  //   );

  //   if (existingProductIndex !== -1) {
  //     const existingProduct = formik.values.products[existingProductIndex];
  //     existingProduct.amount = existingProduct.price * newQuantity;

  //     const updatedProducts = [
  //       ...formik.values.products.filter((f: any) => f.price !== 0),
  //     ];

  //     updatedProducts[existingProductIndex] = { ...existingProduct };

  //     formik.setFieldValue('products', updatedProducts);
  //   }
  // };

  const getTotalAmount = useCallback(() => {
    let total = 0;
    formik.values.products.forEach((product: any) => {
      total += calculateAmount(product.price, product.quantity);
    });

    return total;
  }, [formik.values.products]);

  const getDiscount = () => {
    const result =
      formik.values.discountType === 'Percentage'
        ? (formik.values.discount / 100) * getTotalAmount()
        : formik.values.discount;
    return result;
  };

  const getTax = () => {
    const result =
      formik.values.taxType === 'Percentage'
        ? (formik.values.tax / 100) * getTotalAmount()
        : formik.values.tax;
    return result;
  };

  const finalAmount = useMemo(() => {
    let disam = 0;
    let taxam = 0;
    if (formik.values.discountType === 'Percentage') {
      disam = (formik.values.discount / 100) * getTotalAmount();
    } else {
      disam = formik.values.discount;
    }
    if (formik.values.taxType === 'Percentage') {
      taxam = (formik.values.tax / 100) * getTotalAmount();
    } else {
      taxam = formik.values.tax;
    }
    const amountandDiscount = getTotalAmount() - disam;
    const result = amountandDiscount + Number(taxam);
    return result;
  }, [formik, getTotalAmount]);

  const renderComponent = useCallback(() => {
    if (showPreview) {
      return (
        <div>
          <Button
            type="button"
            variant="transparent"
            size="custom"
            label="Edit Invoice"
            className="px-5 py-2.5 border border-[#0353A4] text-sm bg-transparent cursor-pointer text-[#0353A4] mt-4"
            onClick={() => setShowPreview(false)}
          />
          <InvoicePreview
            form={formik.values as InvoiceDto}
            vendor={selectedVendor}
            selectedInvoiceId={selectedInvoiceId}
            setSelectedInvoiceId={setSelectedInvoiceId}
          />
        </div>
      );
    } else {
      return (
        <div>
          {showAddProductModal && (
            <EnhancedAddProductModal
              onCancel={() => setShowAddProductModal(false)}
              onSuccess={() => {
                setShowAddProductModal(false);
                fetchProductsData(1);
              }}
              businessId={docs[0]?.businessID?._id}
              selectedProduct={null}
            />
          )}
          {showAddVendorModal && (
            <EnhancedAddVendorModal
              onCancel={() => setShowAddVendorModal(false)}
              onSuccess={() => {
                setShowAddVendorModal(false);
                fetchVendorsData(1);
              }}
              businessId={docs[0]?.businessID?._id}
            />
          )}
          <div className="flex items-center justify-between">
            <Breadcrumb text="Invoices" subText="Create invoice" />
            <Button
              type="button"
              variant="transparent"
              size="custom"
              label="Preview"
              className="px-5 py-2.5 border border-[#0353A4] text-sm bg-transparent text-[#0353A4]"
              icon={<Icon name={Icons.EyeOpen} fill="#0353A4" width={20} height={20} />}
              iconPosition="left"
              iconLabelSpacing={3}
              onClick={onPreview}
            />
          </div>
          <form className="gap-4 mt-10" onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 mt-4 gap-9 lg:grid-cols-3 xl:grid-cols-5">
              <div className="lg:col-span-1 xl:col-span-2 rounded-[20px] bg-white p-8 py-5 only:font-circular text-2xl font-normal text-[#353535]">
                <div className="flex flex-col pl-4 mx-auto">
                  <div className="text-left">
                    <p className="text-xl font-medium font-inter text-black/50">
                      Invoice details
                    </p>
                  </div>
                  <div className="w-full pt-5 my-4">
                    <div className="grid flex-1 grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-2">
                      <TextField
                        name="invoiceNumber"
                        type="text"
                        disabled
                        value={formik.values.invoiceNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleChange}
                        label="Invoice Number"
                        error={
                          formik.touched.invoiceNumber ? formik.errors.invoiceNumber : ''
                        }
                        className="bg-[#FFFFFF8C]"
                      />
                      <TextField
                        name="date"
                        type="date"
                        // disabled
                        value={formik.values.date}
                        onChange={formik.handleChange}
                        onBlur={formik.handleChange}
                        label="Date"
                        error={formik.touched.date ? formik.errors.date : ''}
                        className="bg-[#FFFFFF8C]"
                      />
                    </div>
                    <div className="grid flex-1 grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-2">
                      <div className="inline-block">
                        <TextField
                          name="dueDate"
                          type="date"
                          value={formik.values.dueDate}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          label="Due Date"
                          // error={formik.touched.dueDate && formik.values.dueDate < new Date().toISOString().split('T')[0] }
                          className={formik.values.dueDate ? 'shrink-label' : ''}
                        />
                      </div>

                      <CustomSelect
                        options={[
                          { value: 'NGN', label: 'NGN' },
                          { value: 'USD', label: 'USD' },
                        ]}
                        selectedOption={{
                          value: formik.values.currency,
                          label: formik.values.currency,
                        }}
                        handleOptionChange={(option) => {
                          formik.setFieldValue('currency', option?.value);
                        }}
                        label="Currency"
                        name="currency"
                        isSearchable
                        className="h-[2.7rem] rounded-lg bg-[#FFFFFF8C]"
                        error={formik.touched.currency ? formik.errors.currency : ''}
                      />
                    </div>
                    <div className="grid flex-1 grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-2">
                      <div className="flex flex-col">
                        <span className="font-medium font-inter text-sm text-[#000000]">
                          Discount
                        </span>
                        <div className="flex items-center relative border border-[#0000001A] rounded-lg">
                          <button
                            onClick={() =>
                              setShowDiscountOption((prevState) => !prevState)
                            }
                            className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-black/50 bg-white rounded-s-lg"
                            type="button"
                          >
                            <div className="flex items-center gap-2 pr-2 border-r">
                              <span>
                                {formik.values.discountType === 'Percentage'
                                  ? '%'
                                  : formik.values.discountType || 'Select'}
                              </span>
                              <Icon name={Icons.CaretDown} width={15} height={15} />
                            </div>
                          </button>
                          <div className="absolute left-0 top-[40px]">
                            {showDiscountOption && (
                              <div className="z-10 w-24 bg-white divide-y divide-gray-100 rounded-lg shadow">
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                  <li>
                                    <button
                                      onClick={() => {
                                        formik.setFieldValue(
                                          'discountType',
                                          'Percentage'
                                        );
                                        setShowDiscountOption(false);
                                      }}
                                      type="button"
                                      className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    >
                                      <div className="inline-flex items-center">
                                        Percent
                                      </div>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() => {
                                        formik.setFieldValue('discountType', 'Value');
                                        setShowDiscountOption(false);
                                      }}
                                      type="button"
                                      className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    >
                                      <div className="inline-flex items-center">
                                        Value
                                      </div>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="relative w-full">
                            <input
                              type="number"
                              name="discount"
                              value={formik.values.discount}
                              disabled={!formik.values.discountType}
                              onChange={(e) => {
                                formik.setFieldValue('discount', e.target.value);
                              }}
                              className="block p-2.5 w-full z-20 text-sm text-black bg-white rounded-lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <span className="font-medium font-inter text-sm text-[#000000]">
                          Tax
                        </span>
                        <div className="flex  items-center relative border border-[#0000001A] rounded-lg">
                          <button
                            onClick={() => setShowTaxOption((prevState) => !prevState)}
                            className="flex-shrink-0 z-10 inline-flex items-center py-2.5 px-4 text-sm font-medium text-center text-black/50 bg-white rounded-s-lg"
                            type="button"
                          >
                            <div className="flex items-center gap-2 pr-2 border-r">
                              <span>
                                {formik.values.taxType === 'Percentage'
                                  ? '%'
                                  : formik.values.taxType || 'Select'}
                              </span>
                              <Icon name={Icons.CaretDown} width={15} height={15} />
                            </div>
                          </button>
                          <div className="absolute  left-0 top-[40px]">
                            {showTaxOption && (
                              <div className="z-10 w-24 bg-white divide-y divide-gray-100 rounded-lg shadow">
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                  <li>
                                    <button
                                      disabled={isSubmitting}
                                      onClick={() => {
                                        formik.setFieldValue('taxType', 'Percentage');
                                        setShowTaxOption(false);
                                      }}
                                      type="button"
                                      className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    >
                                      <div className="inline-flex items-center">
                                        Percent
                                      </div>
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() => {
                                        formik.setFieldValue('taxType', 'Value');
                                        setShowTaxOption(false);
                                      }}
                                      type="button"
                                      className="inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                    >
                                      <div className="inline-flex items-center">
                                        Value
                                      </div>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            )}
                          </div>
                          <div className="relative w-full">
                            <input
                              name="tax.value"
                              value={formik.values.tax}
                              disabled={!formik.values.taxType}
                              onChange={(e) => {
                                formik.setFieldValue('tax', e.target.value);
                              }}
                              type="number"
                              className="block p-2.5 w-full z-20 text-sm text-black bg-white rounded-lg"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="lg:col-span-1 xl:col-span-3 rounded-[20px] bg-white p-8 py-5 only:font-circular text-2xl font-normal text-[#353535]">
                <div className="flex flex-col">
                  <div className="w-full mx-auto my-4s">
                    <div className="text-left">
                      <p className="text-xl font-medium font-inter text-black/50">
                        Vendor details
                      </p>
                    </div>
                    <div className="mt-9">
                      <div className="space-y-4">
                        {vendorsData && (
                          <CustomSelect
                            options={[
                              ...(vendorsData as { label: any; value: any }[]),
                              {
                                value: 'new',
                                label: 'Add new vendor',
                              },
                            ]}
                            selectedOption={
                              selectedVendor.sellerName
                                ? selectedVendor.sellerName
                                : (vendorsData &&
                                    vendorsData.find(
                                      (vendor) => vendor.label === selectedVendor?.label
                                    )) ||
                                  selectedVendor?.label
                            }
                            handleOptionChange={(option) => {
                              if (option?.value === 'new') {
                                setShowAddVendorModal(true);
                                return;
                              } else {
                                prefillVendor(option);
                              }
                            }}
                            label="Vendor Name"
                            name="vendorName"
                            isSearchable
                            className="h-[2.7rem] rounded-lg bg-white"
                            error={
                              formik.touched.vendorName ? formik.errors.vendorName : ''
                            }
                          />
                        )}

                        <TextField
                          name="vendorAddress"
                          type="text"
                          value={formik.values.vendorAddress}
                          onChange={formik.handleChange}
                          onBlur={formik.handleChange}
                          label="Vendor Address"
                          error={
                            formik.touched.vendorAddress
                              ? formik.errors.vendorAddress
                              : ''
                          }
                          className="bg-white"
                        />
                      </div>

                      <div className="grid flex-1 grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-2">
                        <CustomSelect
                          options={[{ value: 'nigeria', label: 'Nigeria' }]}
                          selectedOption={{
                            value: formik.values.vendorCountry,
                            label: 'Nigeria',
                          }}
                          handleOptionChange={(option) => {
                            formik.setFieldValue('vendorCountry', option);
                          }}
                          label="Country"
                          name="country"
                          isSearchable
                          className="h-[2.7rem] rounded-lg bg-white"
                          error={
                            formik.touched.vendorCountry
                              ? formik.errors.vendorCountry
                              : ''
                          }
                        />
                        <CustomSelect
                          options={StateData.map((item) => ({
                            value: item.state.name,
                            label: item.state.name,
                          }))}
                          selectedOption={{
                            value: formik.values.vendorCity,
                            label: formik.values.vendorCity,
                          }}
                          handleOptionChange={(option) => {
                            formik.setFieldValue('vendorCity', option?.value);
                          }}
                          label="City"
                          name="city"
                          isSearchable
                          className="h-[2.7rem] rounded-lg bg-white"
                          error={
                            formik.touched.vendorCity ? formik.errors.vendorCity : ''
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-6 rounded-[20px] bg-white text-sm pt-4 pb-20 font-medium text-[#353535]">
              <div className="table-responsive">
                <table>
                  <thead className="border-b">
                    <tr>
                      <th>Product</th>
                      <th>Description</th>
                      <th>Unit Price</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formik.values.products.map((prod, index: number) => {
                      return (
                        <tr
                          className="align-top"
                          style={{ borderBottomWidth: '0px' }}
                          key={index}
                        >
                          <td>
                            {productsData && (
                              <CustomSelect
                                options={[
                                  ...(productsData as {
                                    label: any;
                                    value: any;
                                  }[]),
                                  { value: 'new', label: 'Add new product' },
                                ]}
                                handleOptionChange={(option) => {
                                  if (option?.value === 'new') {
                                    setShowAddProductModal(true);
                                    return;
                                  }
                                  handleAddProduct(option, index);
                                }}
                                selectedOption={
                                  prod.product
                                    ? {
                                        label: prod.product.label,
                                        value: prod.product.value,
                                      }
                                    : null // Ensure null if no product is selected
                                }
                                label=""
                                name={`products[${index}].product`}
                                isSearchable
                                className="min-w-[200px] rounded-lg bg-white h-[3rem]"
                                error={
                                  formik.touched.products
                                    ? formik.errors.products?.[index] &&
                                      formik.errors.products?.length > 0
                                      ? JSON.parse(
                                          JSON.stringify(formik.errors.products[index])
                                        ).product
                                      : ''
                                    : ''
                                }
                              />
                            )}
                          </td>
                          <td>
                            <TextField
                              name={`products[${index}].productDescription`}
                              type="text"
                              value={prod.productDescription}
                              onChange={formik.handleChange}
                              onBlur={formik.handleChange}
                              label=""
                              error={
                                formik.touched.products
                                  ? formik.errors.products?.[index] &&
                                    formik.errors.products?.length > 0
                                    ? JSON.parse(
                                        JSON.stringify(formik.errors.products[index])
                                      ).productDescription
                                    : ''
                                  : ''
                              }
                              className="bg-white"
                            />
                          </td>
                          <td>
                            <TextField
                              // name={`products[${index}].price`}
                              name="price"
                              type="text"
                              value={prod.price}
                              onChange={formik.handleChange}
                              onBlur={formik.handleChange}
                              label=""
                              error={
                                formik.touched.products
                                  ? formik.errors.products?.[index] &&
                                    formik.errors.products.length > 0
                                    ? JSON.parse(
                                        JSON.stringify(formik.errors.products[index])
                                      ).price
                                    : ''
                                  : ''
                              }
                              className="bg-white"
                            />
                          </td>
                          <td>
                            <TextField
                              name={`products[${index}].quantity`}
                              type="text"
                              value={prod.quantity}
                              onChange={(event) => {
                                const newValue = event.target.value.replace(
                                  /[^0-9]/g,
                                  ''
                                ); // Allow only numbers
                                formik.handleChange(event); // Update formik state
                                formik.setFieldValue(
                                  `products[${index}].quantity`,
                                  newValue
                                ); // Set validated value back to formik
                              }}
                              onBlur={formik.handleChange}
                              label=""
                              error={
                                formik.touched.products
                                  ? formik.errors.products?.[index] &&
                                    formik.errors.products?.length > 0
                                    ? JSON.parse(
                                        JSON.stringify(formik.errors.products[index])
                                      ).quantity
                                    : ''
                                  : ''
                              }
                              className="bg-white"
                            />
                          </td>
                          {/* <td>{calculateItemAmount} </td> */}
                          <td>
                            <TextField
                              name="amount"
                              type="text"
                              value={
                                !prod.amount ? prod.price : prod.price * prod.quantity
                              }
                              disabled
                              label=""
                              min={1}
                              error={
                                formik.touched.products
                                  ? formik.errors.products?.[index] &&
                                    formik.errors.products.length > 0
                                    ? JSON.parse(
                                        JSON.stringify(formik.errors.products[index])
                                      ).amount
                                    : ''
                                  : ''
                              }
                              className="bg-white "
                            />
                          </td>
                          <td>
                            {formik.values.products?.length > 1 && (
                              <button
                                type="button"
                                className="mt-2"
                                onClick={() => removeProduct(index)}
                              >
                                <Icon name={Icons.Delete} />
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div
                  tabIndex={-1}
                  className="flex items-center w-full mx-4"
                  role="button"
                  onClick={addMoreProduct}
                >
                  <div className="flex items-center gap-1">
                    <Icon name={Icons.Add} />
                    <span className="text-sm font-medium text-[#000000]">
                      Add another product
                    </span>
                  </div>
                  <div className="ml-5 line" />
                </div>
              </div>
              <div className="flex flex-col justify-between px-4 pl-6 mt-12 sm:flex-row">
                <div className="mb-6 sm:mb-0 w-[30rem]">
                  <label
                    htmlFor="note"
                    className="font-medium font-inter text-sm text-[#000000]"
                  >
                    Leave a note
                  </label>
                  <textarea
                    id="note"
                    name="note"
                    rows={5}
                    onChange={formik.handleChange}
                    onBlur={formik.handleChange}
                    className={`form-textarea text-sm font-inter font-normal rounded-lg  
                      ${
                        formik.touched.note && formik.errors.note
                          ? 'bg-red-50 border border-red-500 text-red-900 placeholder:text-red-700 text-sm rounded-lg focus:ring-red-500 focus:border-red-500'
                          : 'bg-gray-50 border border-[#0000001A] placeholder:text-white-dark '
                      }`}
                    defaultValue={formik.values.note}
                  />
                  {formik.errors.note && (
                    <p className="mt-2 text-xs text-red-600 capitalize">
                      {formik.errors.note}
                    </p>
                  )}
                </div>
                <div className="sm:w-2/5">
                  <div className="flex items-center justify-between">
                    <div>Subtotal</div>
                    <div>{formatCurrency(formik.values.currency, getTotalAmount())}</div>
                  </div>
                  <div className="flex items-center justify-between mt-4">
                    <div>Discount</div>
                    <div>
                      {formatCurrency(
                        formik.values.currency,
                        !getDiscount() ? 0 : getDiscount()
                      )}
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-4">
                    <div>Tax</div>
                    <div>
                      {formatCurrency(formik.values.currency, !getTax() ? 0 : getTax())}
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-4 mt-4 font-semibold border-t">
                    <div>Balance due</div>
                    <div>
                      {formatCurrency(
                        formik.values.currency,
                        !finalAmount ? 0 : finalAmount
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      );
    }
  }, [
    formik,
    handleAddProduct,
    addMoreProduct,
    removeProduct,
    showDiscountOption,
    showTaxOption,
    showPreview,
  ]);

  return renderComponent();
}
