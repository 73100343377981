import React, { useState } from 'react';
import Button from '../../../../../Components/Form/Button';
import AddSeatPaymentModal from '../../../../../Components/Modal/AddSeatPaymentModal';
import withCreatePortal from '../../../../../Components/Hoc/withCreatePortal';
import TransactionDetailModal from '../../../../../Components/Modal/Transaction/TransactionDetailModal';
import SubscriptionInvoiceModal from '../../../../../Components/Modal/SubscriptionInvoiceModal';
import { useGetBillingHistory } from '../../../../../hooks/queries-and-mutations/subscription';
import { selectBusiness } from '../../../../../selectors/business-selector';
import { useAppSelector } from '../../../../../store/hooks';
import { BillingHistory, CurrentPlan } from '../../../../../types/subscription.type';
import { formatCurrency } from '../../../../../utils';
import { format } from 'date-fns';
import AddNewEmployee from '../../../../../Components/Modal/AddNewEmployee';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../../../Context/Context';

type Props = {
  currentPlan: CurrentPlan | undefined;
};

const EnhancedAddSeatPaymentModal = withCreatePortal(AddSeatPaymentModal);
const EnhancedTransactionDetail = withCreatePortal(TransactionDetailModal);
const EnhancedSubscriptionInvoiceModal = withCreatePortal(SubscriptionInvoiceModal);
const EnhancedAddNewEmployeeModal = withCreatePortal(AddNewEmployee);

export default function Subscription({ currentPlan }: Props) {
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showTranDetails, setShowTranDetails] = useState(false);
  const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);
  const [selectedBilling, setSelectedBilling] = useState<BillingHistory | null>(null);
  const [showAddEmployer, setShowAddEmployer] = useState(false);
  const { setOpenCollaborators } = useGlobalContext();

  const { docs } = useAppSelector(selectBusiness);
  const { data: billings } = useGetBillingHistory({
    businessId: docs[0].businessID._id,
  });

  const navigate = useNavigate();

  const handleAddCollaborator = () => {
    setOpenCollaborators(true);
    setShowAddEmployer(false);
    navigate('/settings/collaborators');
  };

  return (
    <div>
      <div className="relative mt-10">
        <div className="flex items-center justify-around gap-3 bg-subscriptionBg overflow-x rounded-md p-9">
          <div className="w-fit">
            <div className="text-black/40 text-xs font-normal">Current plan</div>
            <div className="mt-2 text-xl font-medium text-black">
              {currentPlan?.plan ?? ''}
            </div>
          </div>
          <div className="w-fit ">
            <div className="text-black/40 text-xs font-normal">Price/Seat</div>
            <div className="mt-2 text-xl font-medium text-black">
              {formatCurrency('NGN', currentPlan?.price_per_seat)}/User
            </div>
          </div>
          <div className="">
            <div className="text-black/40 text-xs font-normal">Number of seat</div>
            <div className="mt-2 text-xl font-medium text-black">
              {currentPlan?.seat_subbed}
            </div>
          </div>
          <div className="w-fit ">
            <div className="text-black/40 text-xs font-normal">Total per month</div>
            <div className="mt-2 text-xl font-medium text-black">
              {formatCurrency(
                'NGN',
                Number(currentPlan?.price_per_seat ?? 0) *
                  Number(currentPlan?.seat_subbed ?? 0)
              )}
            </div>
          </div>
          <Button
            type="button"
            variant="outline"
            size="sm"
            label="Add seat"
            className="text-blueTextColor bg-transparent w-fit h-9s"
            onClick={() => setShowPaymentModal(true)}
          />
          <Button
            disabled={true}
            type="button"
            variant="primary"
            size="sm"
            label="Upgrade"
            className="text-white"
          />
        </div>
      </div>
      <div className="relative mt-10">
        <div className="flex items-center justify-between bg-subscriptionBg rounded-md p-9">
          <div>
            <div className="text-black text-sm font-bold">
              You have {currentPlan?.unoccupied_seat ?? 0} seats left out of{' '}
              {currentPlan?.total_possible_seat ?? 0}
            </div>
          </div>
          <Button
            type="button"
            variant="outline"
            size="sm"
            label="Add Collaborator"
            className="text-blueTextColor bg-transparent w-fit"
            onClick={() => {
              handleAddCollaborator();
            }}
          />
        </div>
      </div>
      <div className="my-10">
        <p className="mb-5 font-medium text-xl">Billing history</p>
        <table>
          <thead className="bg-[#0353A4B0] text-white rounded mb-3">
            <tr>
              <th>Plan</th>
              <th>Amount</th>
              <th>Status</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="bg-[#B9D6F224]">
            {(billings ?? [])?.map((billing) => (
              <tr key={billing._id} className="py-3">
                <td className="whitespace-nowraps">{billing.plan}</td>
                <td className="whitespace-nowraps">
                  {formatCurrency('NGN', billing?.amount)}
                </td>
                <td className="whitespace-nowraps">Paid</td>
                <td className="whitespace-nowraps">
                  {format(new Date(billing.date), 'dd, MMM yyyy')}
                </td>
                {billing?.paid ? (
                  <td
                    className="whitespace-nowraps text-[#0353A4] cursor-pointer"
                    onClick={() => {
                      setSelectedBilling(billing);
                      setShowTranDetails(true);
                    }}
                  >
                    View receipt
                  </td>
                ) : (
                  <td
                    className="whitespace-nowraps text-[#0353A4] cursor-pointer"
                    onClick={() => {
                      setSelectedBilling(billing);
                      setShowInvoiceDetails(true);
                    }}
                  >
                    View invoice
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {showPaymentModal && (
        <EnhancedAddSeatPaymentModal
          onCancel={() => {
            setShowPaymentModal(false);
          }}
          onSuccess={() => {
            setShowPaymentModal(false);
          }}
          planId={0}
          email={docs[0].businessID?.email}
          businessId={docs[0]?.businessID?._id}
        />
      )}
      {showTranDetails && (
        <EnhancedTransactionDetail
          transaction={{
            currency: selectedBilling?.currency,
            amount: selectedBilling?.amount,
            vendorName: docs[0].businessID.name,
            invoiceNumber: selectedBilling?.payment_ref,
            createdAt: selectedBilling?.date,
            paidAt: selectedBilling?.due_date,
            id: selectedBilling?._id,
            paymentType: selectedBilling?.type,
            paymentProof: selectedBilling?.paymentProof,
          }}
          onCancel={() => {
            setShowTranDetails(false);
          }}
          onSuccess={() => {
            setShowTranDetails(false);
          }}
        />
      )}
      {showInvoiceDetails && (
        <EnhancedSubscriptionInvoiceModal
          business={docs[0].businessID}
          billing={selectedBilling}
          onCancel={() => {
            setShowInvoiceDetails(false);
          }}
        />
      )}
      {showAddEmployer && (
        <EnhancedAddNewEmployeeModal
          onCancel={() => {
            setShowAddEmployer(false);
          }}
          onSuccess={() => {
            setShowAddEmployer(false);
          }}
        />
      )}
    </div>
  );
}
