import { useCallback, useEffect, useState } from 'react';
import { Icon, Icons } from '../Icon';
import { useDropzone } from 'react-dropzone';
import { useLocation } from 'react-router-dom';

type Props = {
  label: string;
  getFile?: (file: File) => void;
  getMultipleFile?: (files: File[]) => void;
  error?: string;
  isMultiple?: boolean;
  value?: string | null;
  name?: string;
  fileName?: string;
  acceptFile?: string | string[] | undefined;
};

export default function Upload({ getFile, label, error }: Props) {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [uploadedFileName, setUploadedFileName] = useState<string>('');
  const [controlFileAccepted, setControlFileAccepted] = useState<boolean>(false);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      if (file) {
        getFile && getFile(file);
        const reader = new FileReader();
        reader.onload = async () => {
          if (reader.result) {
            setPreviewUrl(reader.result as string);
          }
        };
        reader.readAsDataURL(file);
        setUploadedFileName(file.name);
      }
    },
    [getFile]
  );

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/vendors') {
      setControlFileAccepted(true);
    }
  }, [location.pathname, controlFileAccepted]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: controlFileAccepted
      ? { 'text/csv': ['.csv'] }
      : {
          'image/jpeg': ['.jpg', '.jpeg'],
          'image/png': ['.png'],
          'application/pdf': ['.pdf'],
          'application/msword': ['.doc'],
        },
  });

  return (
    <div className="mt-5">
      <span className="text-[#000000] font-medium text-sm font-inter">{label}</span>
      <div
        {...getRootProps()}
        className="px-14 bg-white border border-dashed border-[#00000033] rounded-lg my-2 gap-3 py-4 flex items-center justify-center "
      >
        <div className="bg-[#022C75] rounded-full p-4">
          <Icon name={Icons.Upload} />
        </div>
        {previewUrl ? (
          <div className="flex flex-col items-center">
            <img
              src={previewUrl ? previewUrl : ''}
              alt="Preview"
              className="max-w-full mt-2 max-h-40"
            />
            <span className="mt-2 text-xs">{uploadedFileName}</span>
          </div>
        ) : (
          <label className="text-sm font-normal cursor-pointer" htmlFor="upload">
            <span className="text-[#022C75]">Drag file here</span> to upload or choose
            file
          </label>
        )}
        <input type="file" hidden id="upload" {...getInputProps()} />
      </div>
      {error && <p className="mt-2 text-xs text-red-600 capitalize">{error}</p>}
    </div>
  );
}
